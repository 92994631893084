/*=========================================================================================
  Author: Hazem Ashraf
  Author URL: https://www.linkedin.com/in/hazem-ashraf-1242169b/
==========================================================================================*/

import axios from "@/axios.js";

export default {

  fetchItemsCities({ commit }) {

    return new Promise((resolve, reject) => {

      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/cities`)
        .then(response => {
  
          commit("SET_ITEMS", response.data.response.data);
    
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
