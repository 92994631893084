/*=========================================================================================
  Author: Hazem Ashraf
  Author URL: https://www.linkedin.com/in/hazem-ashraf-1242169b/
==========================================================================================*/

import axios from "@/axios.js";
import endpoints from "../../endpoints";
const rolesUrl = endpoints.ROLES_ENDPOINT

export default {


  getAlls ({ commit }) {
    
    return new Promise((resolve, reject) => {

      axios
        .get(rolesUrl)
        .then(response => {
          commit("SET_ITEMS", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getById({ commit }, id) {
    return new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.ROLES_ENDPOINT}/${id}`)
      .then((res) => {
        commit("SET_PERMISSIONS_BY_ID", res.data.response.data);
        resolve(res.data.response);
      }) .catch(error => {
        reject(error);
      });
    });

  },
  getPermissions({commit}){
    return new Promise((resolve, reject) => {
      axios
        .get(endpoints.PERMISSIONS_ENDPOINT)
        .then(response => {
          commit("SET_PERMISSIONS", response.data.response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }


};
